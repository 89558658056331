getIndicesOf = (str, searchStr, caseSensitive) ->
  startIndex = 0
  searchStrLen = searchStr.length
  index = undefined
  indices = []
  if !caseSensitive
    str = str.toLowerCase()
    searchStr = searchStr.toLowerCase()
  while (index = str.indexOf(searchStr, startIndex)) > -1
    indices.push index
    startIndex = index + searchStrLen
  indices

calculateCircle = (center,radius,angle) ->

  radians = (Math.PI / 180) * (angle-90)
  cos = Math.cos(radians)
  sin = Math.sin(radians)
  nx = cos * radius + center[0]
  ny = sin * radius + center[1]
  return [nx,ny]

helper=
  getIndicesOf:   getIndicesOf
  calculateCircle:calculateCircle
module.exports=helper

