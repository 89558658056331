helper   = require('../helper')

arrowId = 1
class Arrow
  constructor: (data) ->

    @borderColor="#000"
    @fillColor  ="#eee"
    @opacity  = 1
    @fontSize   = 20
    @text      = ""
    @class    ="arrow"
    @arrow      = [false,true]   #start, front arrow 
    @center    = [0,0]
    @radius    = 210
    @startAngle = 0
    @endAngle  = 0
    @width      = 25
    @plasmid    = data.plasmid

    for index,value of data
      this[index]=value

    @id      = arrowId

    if @text[@text.length-1]==')'
      @text = @text.slice(0,@text.length-3)
    @text = @text.trim()  
    arrowId++
  calculatePath: () ->

    if @startAngle>@endAngle
      startAngle=@endAngle
      endAngle  =@startAngle
    else  
      endAngle=@endAngle
      startAngle  =@startAngle


    r = @radius

    length  = 2 * Math.PI * r

    start   = helper.calculateCircle(@center,r,startAngle)
    middle  = helper.calculateCircle(@center,r,startAngle+(endAngle-startAngle)/2)
    end    = helper.calculateCircle(@center,r,endAngle)

    fix  = 0
    fix = 1 if Math.abs(endAngle-startAngle)>180 

    d = [
          "M", start[0], start[1], 
          "A", r, r, 0, fix , 1, end[0], end[1]
      ].join(" ");
  updatePath: () ->
    d=@calculatePath()
    $("path.select",@plasmid.svgOb.root()).attr("d",d)

  hide: () ->
      $("path.select",@plasmid.svgOb.root()).hide()
  show: () ->
      $("path.select",@plasmid.svgOb.root()).show()

  draw: (paper) ->
    attrs=
      "stroke":@borderColor
      "stroke-width":@width
      "stroke-opacity":@opacity
    if @startAngle>@endAngle
      startAngle=@endAngle
      endAngle  =@startAngle
      attrs["arrow-start"]="block-narrow-short"   if @arrow[1]
      attrs["arrow-end"]="block-narrow-short"   if @arrow[0]      
    else  
      endAngle=@endAngle
      startAngle  =@startAngle
      attrs["arrow-start"]="block-narrow-short"   if @arrow[0]
      attrs["arrow-end"]="block-narrow-short"   if @arrow[1]
    r = @radius

    length  = 2 * Math.PI * r

    if Math.abs(endAngle-startAngle)<5
      delete attrs["arrow-start"]
      delete attrs["arrow-end"]

    start   = helper.calculateCircle(@center,r,startAngle)
    middle  = helper.calculateCircle(@center,r,startAngle+(endAngle-startAngle)/2)
    end    = helper.calculateCircle(@center,r,endAngle)



    fix  = 0
    fix = 1 if Math.abs(endAngle-startAngle)>180 

    d = [
          "M", start[0], start[1], 
          "A", r, r, 0, fix , 1, end[0], end[1]
      ].join(" ");



    circle=paper.path(d);
    circle.attr(attrs)
    circle.node.setAttribute("class",@class+" id"+@id)
    circle.node.setAttribute("data",@id)

    offsetStart = 0
    offsetEnd   = 0

    offsetEnd   = 1    if attrs["arrow-end"]
    offsetStart = 1   if attrs["arrow-start"]

    r = @radius

    length  = 2 * Math.PI * r


    start   = helper.calculateCircle(@center,r,startAngle+(offsetStart/length*360))
    middle  = helper.calculateCircle(@center,r,startAngle+(endAngle-startAngle)/2)
    end    = helper.calculateCircle(@center,r,endAngle-(offsetEnd/length*360)) #move 2px back

    fix  = 0
    fix = 1 if Math.abs(endAngle-startAngle)>180 

    d = [
          "M", start[0], start[1], 
          "A", r, r, 0, fix , 1, end[0], end[1]
      ].join(" ");

    circle=paper.path(d);
    attrs["stroke"]      = @fillColor
    attrs["stroke-width"]  = @width-2

    circle.attr(attrs)
    circle.node.setAttribute("class",@class+" id"+@id)
    circle.node.setAttribute("data",@id)


    d = [
          "M", start[0], start[1], 
          "A", r, r, 0, fix , 1, end[0], end[1]
      ].join(" ");

 
    l=@text.length*0.6*@fontSize #0.42 #0.7 #fontSize to width 

    lineLength   = 2 * Math.PI * r
    angleLength = l/lineLength*360
        
    offsetAngle=((Math.abs(endAngle-startAngle)-angleLength)/2)
    offset=offsetAngle*lineLength/360            

    unless attrs["arrow-end"]
      start   = helper.calculateCircle(@center,r-@width/2,endAngle)
      end    = helper.calculateCircle(@center,r+@width/2,endAngle)
  
      d = [
            "M", start[0], start[1], 
            "L", start[0], start[1], end[0], end[1]
        ].join(" ");
            
      line=paper.path(d);  
      line.attr({"stroke":@borderColor,"stroke-width":1,"stroke-opacity":@opacity})  
      line.node.setAttribute("class",@class+" id"+@id)
      line.node.setAttribute("data",@id)

    unless attrs["arrow-start"]    
      start   = helper.calculateCircle(@center,r-@width/2,startAngle)
      end    = helper.calculateCircle(@center,r+@width/2,startAngle)
  
      d = [
            "M", start[0], start[1], 
            "L", start[0], start[1], end[0], end[1]
        ].join(" ");
            
      line=paper.path(d);  
      line.attr({"stroke":@borderColor,"stroke-width":1,"stroke-opacity":@opacity})
      line.node.setAttribute("class",@class+" id"+@id)
      line.node.setAttribute("data",@id)




    ###
    #full circle
    r = @radius-5
    start   = helper.calculateCircle(@center,r,@startAngle)
    middle  = helper.calculateCircle(@center,r,@startAngle+180)

  
          d = [
          "M", start[0], start[1], 
          "A", r, r, 0, 0, 1, middle[0], middle[1]
          "A", r, r, 0, 0, 1, start[0], start[1]

      ].join(" ");
    ###
    r = @radius
    if offset<0
      startAngle=startAngle+offsetAngle #*(@radius/r)
      endAngle=endAngle-offsetAngle #*(@radius/r)          
      #r = @radius-40 
      r = @plasmid.getRadius(startAngle,endAngle, @text)
      lineLength   = 2 * Math.PI * r
      angleLength = l/lineLength*360      
      offsetAngle=((Math.abs(endAngle-startAngle)-angleLength)/2)
      startAngle=startAngle+offsetAngle #*(@radius/r)
      endAngle=endAngle-offsetAngle #*(@radius/r)            
      offset=0


    sTmp=(startAngle+90)%360
    eTmp=(endAngle+90)%360

    if sTmp<180
      if eTmp<180
        sumTop    =eTmp-sTmp
        sumBottom  =0
      else
        sumTop    =180-sTmp
        sumBottom  =eTmp-180

    else
      if eTmp<180
        sumTop    =eTmp
        sumBottom  =360-sTmp
      else
        sumBottom=1
        sumTop=0  

    if sumBottom > sumTop
      r = r+5

      curve  = 0
      start   = helper.calculateCircle(@center,r,endAngle)
      end    = helper.calculateCircle(@center,r,startAngle)
    else  
      r = r-5

      curve  =1
      start   = helper.calculateCircle(@center,r,startAngle)
      end    = helper.calculateCircle(@center,r,endAngle)


  
    d = [
          "M", start[0], start[1], 
          "A", r, r, 0, fix, curve, end[0], end[1]

      ].join(" ");
        
        
    circle=paper.path(d);
    circle.attr({"stroke":"#000000","stroke-width":0})
    circle.node.setAttribute("class",@class+" id"+@id)
    circle.node.setAttribute("data",@id)
    circle.node.id="arrow"+@id
        
    text=paper.text(offset,0,@text);
    text.attr({ "font-size": @fontSize,"text-anchor":"left", "font-family": "Courier New", "font-style": "normal","textpath":"arrow"+@id});
    text.node.style=""
    text.node.setAttribute("class",@class+" id"+@id)
    text.node.setAttribute("data",@id)


module.exports=Arrow
      
