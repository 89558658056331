// Generated by CoffeeScript 1.10.0
(function() {
  var calculateCircle, getIndicesOf, helper;

  getIndicesOf = function(str, searchStr, caseSensitive) {
    var index, indices, searchStrLen, startIndex;
    startIndex = 0;
    searchStrLen = searchStr.length;
    index = void 0;
    indices = [];
    if (!caseSensitive) {
      str = str.toLowerCase();
      searchStr = searchStr.toLowerCase();
    }
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
      indices.push(index);
      startIndex = index + searchStrLen;
    }
    return indices;
  };

  calculateCircle = function(center, radius, angle) {
    var cos, nx, ny, radians, sin;
    radians = (Math.PI / 180) * (angle - 90);
    cos = Math.cos(radians);
    sin = Math.sin(radians);
    nx = cos * radius + center[0];
    ny = sin * radius + center[1];
    return [nx, ny];
  };

  helper = {
    getIndicesOf: getIndicesOf,
    calculateCircle: calculateCircle
  };

  module.exports = helper;

}).call(this);
