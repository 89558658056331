// Generated by CoffeeScript 1.10.0
(function() {
  var $, Arrow, Feature, Nt, Pablo, Plasmid, Raphael, Select, fs, helper, jQuery, plasmidId, seq;

  seq = require('bionode-seq');

  Nt = require('ntseq');

  helper = require('../helper');

  Arrow = require('./arrow');

  Feature = require('./feature');

  Select = require('./select');

  Pablo = window.Pablo;

  Raphael = window.Raphael;

  jQuery = require('jquery');

  $ = jQuery;

  fs = require('fs');


  /*
  todo order features after probility
   */

  plasmidId = 1;

  Plasmid = (function() {
    function Plasmid(dna, data, features) {
      var index, value;
      window.activePlasmid = this;
      this.id = plasmidId;
      plasmidId++;
      this.scale = 1;
      this.browser = false;
      this.ob = null;
      this.obCopy = $("<textarea/>").appendTo("body");
      this.obCopy.css({
        position: "absolute",
        top: "-100px",
        left: "-100px"
      });

      /*   
        
      @df = new Array()
       */
      this.df = [
        {
          "name": "AmpR (3)",
          "typ": "feature",
          "bp": "ATGAGTATTCAACATTTCCGTGTCGCCCTTATTCCCTTTTTTGCGGCATTTTGCCTTCCTGTTTTTGCTCACCCAGAAACGCTGGTGAAAGTAAAAGATGCTGAAGATCAGTTGGGTGCACGAGTGGGTTACATCGAACTGGATCTCAACAGCGGTAAGATCCTTGAGAGTTTTCGCCCCGAAGAACGTTTTCCAATGATGAGCACTTTTAAAGTTCTGCTATGTGGCGCGGTATTATCCCGTGTTGACGCCGGGCAAGAGCAACTCGGTCGCCGCATACACTATTCTCAGAATGACTTGGTTGAGTACTCACCAGTCACAGAAAAGCATCTTACGGATGGCATGACAGTAAGAGAATTATGCAGTGCTGCCATAACCATGAGTGATAACACTGCGGCCAACTTACTTCTGACAACGATCGGAGGACCGAAGGAGCTAACCGCTTTTTTGCACAACATGGGGGATCATGTAACTCGCCTTGATCGTTGGGAACCGGAGCTGAATGAAGCCATACCAAACGACGAGCGTGACACCACGATGCCTGCAGCAATGGCAACAACGTTGCGCAAACTATTAACTGGCGAACTACTTACTCTAGCTTCCCGGCAACAATTAATAGACTGGATGGAGGCGGATAAAGTTGCAGGACCACTTCTGCGCTCGGCCCTTCCGGCTGGCTGGTTTATTGCTGATAAATCTGGAGCCGGTGAGCGTGGGTCTCGCGGTATCATTGCAGCACTGGGGCCAGATGGTAAGCCCTCCCGTATCGTAGTTATCTACACGACGGGGAGTCAGGCAACTATGGATGAACGAAATAGACAGATCGCTGAGATAGGTGCCTCACTGATTAAGCATTGGTAA"
        }, {
          "name": "CAP binding site",
          "typ": "feature",
          "bp": "TAATGTGAGTTAGCTCACTCAT"
        }, {
          "name": "KanR",
          "typ": "feature",
          "bp": "ATGGCAATTACCTTATCCGCAACTTCTTTACCTATTTCCGCCCGGATCCGGGCAGGTTCTCCGGCCGCTTGGGTGGAGAGGCTATTCGGCTATGACTGGGCACAACAGACAATCGGCTGCTCTGATGCCGCCGTGTTCCGGCTGTCAGCGCAGGGGCGCCCGGTTCTTTTTGTCAAGACCGACCTGTCCGGTGCCCTGAATGAACTGCAGGACGAGGCAGCGCGGCTATCGTGGCTGGCCACGACGGGCGTTCCTTGCGCAGCTGTGCTCGACGTTGTCACTGAAGCGGGAAGGGACTGGCTGCTATTGGGCGAAGTGCCGGGGCAGGATCTCCTGTCATCTCACCTTGCTCCTGCCGAGAAAGTATCCATCATGGCTGATGCAATGCGGCGGCTGCATACGCTTGATCCGGCTACCTGCCCATTCGACCACCAAGCGAAACATCGCATCGAGCGAGCACGTACTCGGATGGAAGCCGGTCTTGTCGATCAGGATGATCTGGACGAAGAGCATCAGGGGCTCGCGCCAGCCGAACTGTTCGCCAGGCTCAAGGCGCGCATGCCCGACGGCGAGGATCTCGTCGTGACCCATGGCGATGCCTGCTTGCCGAATATCATGGTGGAAAATGGCCGCTTTTCTGGATTCATCGACTGTGGCCGGCTGGGTGTGGCGGACCGCTATCAGGACATAGCGTTGGCTACCCGTGATATTGCTGAAGAGCTTGGCGGCGAATGGGCTGACCGCTTCCTCGTGCTTTACGGTATCGCCGCTCCCGATTCGCAGCGCATCGCCTTCTATCGCCTTCTTGACGAGTTCTTCTGA"
        }, {
          "name": "M13 fwd",
          "typ": "feature",
          "bp": "GTAAAACGACGGCCAGT"
        }, {
          "name": "SV40 ori",
          "typ": "feature",
          "bp": "ATCCCGCCCCTAACTCCGCCCAGTTCCGCCCATTCTCCGCCCCATGGCTGACTAATTTTTTTTATTTATGCAGAGGCCGAGGCCGCCTCGGCCTCTGAGCTATTCCAGAAGTAGTGAGGAGGCTTTTTTGGAGGCC"
        }, {
          "name": "T7 promoter",
          "typ": "feature",
          "bp": "TAATACGACTCACTATAGG"
        }, {
          "name": "M13 rev",
          "typ": "feature",
          "bp": "CAGGAAACAGCTATGAC"
        }, {
          "name": "SV40 poly(A) signal (2)",
          "typ": "feature",
          "bp": "AACTTGTTTATTGCAGCTTATAATGGTTACAAATAAAGCAATAGCATCACAAATTTCACAAATAAAGCATTTTTTTCACTGCATTCTAGTTGTGGTTTGTCCAAACTCATCAATGTATCTTATCATGTCTGGATC"
        }, {
          "name": "T3 promoter",
          "typ": "feature",
          "bp": "AATTAACCCTCACTAAAGG"
        }, {
          "name": "lac UV5 promoter",
          "typ": "feature",
          "bp": "TTTACACTTTATGCTTCCGGCTCGTATAATG"
        }, {
          "name": "lac operator (2)",
          "typ": "feature",
          "bp": "GGAATTGTGAGCGGATAACAATTCC"
        }, {
          "name": "ori",
          "typ": "feature",
          "bp": "TTGAGATCCTTTTTTTCTGCGCGTAATCTGCTGCTTGCAAACAAAAAAACCACCGCTACCAGCGGTGGTTTGTTTGCCGGATCAAGAGCTACCAACTCTTTTTCCGAAGGTAACTGGCTTCAGCAGAGCGCAGATACCAAATACTGTCCTTCTAGTGTAGCCGTAGTTAGGCCACCACTTCAAGAACTCTGTAGCACCGCCTACATACCTCGCTCTGCTAATCCTGTTACCAGTGGCTGCTGCCAGTGGCGATAAGTCGTGTCTTACCGGGTTGGACTCAAGACGATAGTTACCGGATAAGGCGCAGCGGTCGGGCTGAACGGGGGGTTCGTGCACACAGCCCAGCTTGGAGCGAACGACCTACACCGAACTGAGATACCTACAGCGTGAGCTATGAGAAAGCGCCACGCTTCCCGAAGGGAGAAAGGCGGACAGGTATCCGGTAAGCGGCAGGGTCGGAACAGGAGAGCGCACGAGGGAGCTTCCAGGGGGAAACGCCTGGTATCTTTATAGTCCTGTCGGGTTTCGCCACCTCTGACTTGAGCGTCGATTTTTGTGATGCTCGTCAGGGGGGCGGAGCCTATGGAAA"
        }, {
          "name": "AmpR promoter (2)",
          "typ": "feature",
          "bp": "CGCGGAACCCCTATTTGTTTATTTTTCTAAATACATTCAAATATGTATCCGCTCATGAGACAATAACCCTGATAAATGCTTCAATAATATTGAAAAAGGAAGAGT"
        }, {
          "name": "M13 ori (3)",
          "typ": "feature",
          "bp": "ACGCGCCCTGTAGCGGCGCATTAAGCGCGGCGGGTGTGGTGGTTACGCGCAGCGTGACCGCTACACTTGCCAGCGCCCTAGCGCCCGCTCCTTTCGCTTTCTTCCCTTCCTTTCTCGCCACGTTCGCCGGCTTTCCCCGTCAAGCTCTAAATCGGGGGCTCCCTTTAGGGTTCCGATTTAGTGCTTTACGGCACCTCGACCCCAAAAAACTTGATTTGGGTGATGGTTCACGTAGTGGGCCATCGCCCTGATAGACGGTTTTTCGCCCTTTGACGTTGGAGTCCACGTTCTTTAATAGTGGACTCTTGTTCCAAACTGGAACAACACTCAACCCTATCTCGGGCTATTCTTTTGATTTATAAGGGATTTTGCCGATTTCGG"
        }
      ];
      this.features = [];
      this.history = [];
      this.name = "Untiled";
      this.author = "";
      this.description = "";
      this.displayOptions = {
        editable: false,
        features: true,
        cutters: false,
        primers: false,
        linear: false
      };
      this.size = [1500, 1500];
      this.center = [750, 750];
      this.radius = 250;
      this.layers = [];
      this.typ = seq.checkType(dna);
      this.restrictionEnzyms = [["EcoRI", "GAATTC", 0, 0], ["XhoI", "CTCGAG", 0, 0], ["XbaI", "TCTAGA", 0, 0]];
      this.commonFeatures = features;
      this.cutters = {};
      for (index in data) {
        value = data[index];
        this[index] = value;
      }
      this.bp = dna.replace(/[^atcgATCG]/g, '');
      this.rbp = seq.complement(dna);
      this.length = this.bp.length;
      this.frames = seq.getAllOpenReadingFrames(this.bp);
      this.findCutters();
      this.findFeatures();
    }

    Plasmid.prototype.writeFeatures = function() {
      var content, feature, j, len, ref;
      content = ">Vektor\n" + this.bp + "\n";
      ref = this.df;
      for (j = 0, len = ref.length; j < len; j++) {
        feature = ref[j];
        content += ">" + feature.name + "\n" + feature.bp + "\n";
      }
      return fs.writeFile('fasta.txt', content, function(err) {
        return console.log('wrote > fasta.txt');
      });
    };

    Plasmid.prototype.findCutters = function() {
      var enzyme, indexes, j, len, ref, results;
      ref = this.restrictionEnzyms;
      results = [];
      for (j = 0, len = ref.length; j < len; j++) {
        enzyme = ref[j];
        indexes = helper.getIndicesOf(this.bp, enzyme[1], false);
        if (indexes) {
          results.push(this.cutters[enzyme[0]] = indexes);
        } else {
          results.push(void 0);
        }
      }
      return results;
    };

    Plasmid.prototype.alignDNA = function(dna, feature, direction) {
      var add, gapExt, gapOpen, isLocal, match, mis, query, results, rst, target;
      add = 0;
      if (direction) {
        query = seq.reverse(feature[1]);
      } else {
        query = feature[1];
      }
      results = [];
      while (dna.length > query.length) {
        target = dna;
        isLocal = true;
        match = 1;
        mis = -1;
        gapOpen = -1;
        gapExt = -1;
        rst = bsa_align(isLocal, target, query, [match, mis], [gapOpen, gapExt]);
        if ((rst[0] / query.length) * 100 > 90) {
          this.features.push([feature[0], add + rst[1], rst[2] - rst[1], direction]);
        }
        dna = dna.slice(rst[2]);
        add = rst[2];
        if (!rst[2]) {
          break;
        } else {
          results.push(void 0);
        }
      }
      return results;
    };

    Plasmid.prototype.alignDNAFast = function(dna, querySeq, feature, direction) {
      var f, map, result;
      if (dna.size() < querySeq.size()) {
        return [];
      }
      f = [];
      map = new Nt.MatchMap(querySeq, dna);
      result = map.best();
      if (result.matches / feature.bp.length >= 0.95) {
        return [[feature.name, result.position, result.matches, direction]];

        /*
        results=map.top()
        for i in [1...results.length-1]
          result=results[i]
          break if result.matches/feature.bp.length<0.95
          f.push [feature.name,result.position,result.matches,direction]
         */
      }

      /*
      results=map.top()
      for result in results
      
        break if result.matches/feature.bp.length<0.95
        f.push [feature.name,result.position,result.matches,direction]
       */
      return f;
    };

    Plasmid.prototype.detectFeature = function(bp, offset, i) {
      var after, afterMatch, before, beforeMatch, dna, feature, j, k, len, len1, m, match, matches, matchesNom, matchesRev, o, querySeq, ref, results;
      if (i >= this.commonFeatures.length) {
        return;
      }
      feature = this.commonFeatures[i];
      dna = new Nt.Seq();
      dna.read(bp);
      querySeq = new Nt.Seq();
      querySeq.read(feature.bp);
      matchesNom = this.alignDNAFast(dna, querySeq, feature, 0);
      matchesRev = this.alignDNAFast(dna, querySeq.complement(), feature, 1);
      matches = [];
      for (j = 0, len = matchesNom.length; j < len; j++) {
        m = matchesNom[j];
        matches.push(m);
      }
      for (k = 0, len1 = matchesRev.length; k < len1; k++) {
        m = matchesRev[k];
        matches.push(m);
      }
      if (matches.length) {
        matches = matches.sort(function(a, b) {
          return a[1] - b[1];
        });
        beforeMatch = 0;
        results = [];
        for (i = o = 0, ref = matches.length - 1; 0 <= ref ? o <= ref : o >= ref; i = 0 <= ref ? ++o : --o) {
          afterMatch = -1;
          match = matches[i];
          if (i > 0) {
            beforeMatch = matches[i - 1][1] + matches[i - 1][2];
          }
          if (i < matches.length - 1) {
            afterMatch = matches[i + 1][1];
          }
          before = bp.slice(beforeMatch, match[1]);
          after = bp.slice(match[1] + match[2], afterMatch);
          this.df.push(feature);
          this.features.push([feature.name, match[1] + offset, match[2], match[3]]);
          this.detectFeature(before, offset, i + 1);
          results.push(this.detectFeature(after, offset + match[1] + match[2], i + 1));
        }
        return results;
      } else {
        return this.detectFeature(bp, offset, i + 1);
      }
    };

    Plasmid.prototype.findFeatures = function() {
      var elapse, time_start;
      time_start = new Date().getTime();
      this.commonFeatures = this.df.concat(this.commonFeatures);
      this.detectFeature(this.bp, 0, 0);
      elapse = (new Date().getTime() - time_start) / 1000.0;
      return console.log("findFeatures", elapse.toFixed(3) + "s");
    };

    Plasmid.prototype.drawSVG = function(paper) {
      var angle, arrow, circle, cutters, d, end, feature, fix, i, index, j, k, l, len, line, lmax, middle, name, pathId, pos, positions, r, ref, ref1, results, start, text;
      l = this.length;
      cutters = this.cutters;
      circle = paper.circle(this.center[0], this.center[1], this.radius - 4);
      circle.attr({
        "stroke": "#000",
        "stroke-width": 2
      });
      circle = paper.circle(this.center[0], this.center[1], this.radius);
      circle.attr({
        "stroke": "#000",
        "stroke-width": 2
      });
      text = paper.text(this.center[0], this.center[1] - 10, this.name);
      text.attr({
        "font-size": 20,
        "font-family": "Times New Roman,Georgia,Serif",
        "font-weight": "bold",
        "font-style": "normal"
      });
      text = paper.text(this.center[0], this.center[1] + 10, this.length + "bp");
      text.attr({
        "font-size": 16,
        "font-family": "Times New Roman,Georgia,Serif",
        "font-style": "normal"
      });
      if (this.displayOptions.features) {
        ref = this.features;
        for (j = 0, len = ref.length; j < len; j++) {
          feature = ref[j];
          text = feature[0];
          arrow = new Arrow({
            center: this.center,
            radius: this.radius - 40,
            text: text,
            plasmid: this
          });
          if (feature[3]) {
            arrow.endAngle = (feature[1] / l) * 360;
            arrow.startAngle = ((feature[1] + feature[2]) / l) * 360;
          } else {
            arrow.startAngle = (feature[1] / l) * 360;
            arrow.endAngle = ((feature[1] + feature[2]) / l) * 360;
          }
          arrow.draw(paper);
        }
      }
      if (this.browser) {
        this.selecter = new Select({
          center: this.center,
          radius: this.radius - 2,
          text: "",
          startAngle: 10,
          endAngle: 50,
          fillColor: "rgb(100,100,100)",
          borderColor: "rgb(100,100,100)",
          opacity: 0.3,
          "class": "select",
          plasmid: this
        });
        this.selecter.draw(paper);
      }
      if (this.displayOptions.cutters) {
        for (name in cutters) {
          positions = cutters[name];
          for (index in positions) {
            pos = positions[index];
            angle = (pos / l) * 360;
            start = helper.calculateCircle(this.center, this.radius, angle);
            end = helper.calculateCircle(this.center, this.radius + 30, angle);
            line = paper.path("M" + start[0] + " " + start[1] + "L" + end[0] + " " + end[1] + "L" + (end[0] + 100) + " " + end[1]);
            line.attr({
              "stroke": "#000",
              "stroke-width": 1
            });
            text = paper.text(end[0] + 105, end[1] - 2, name);
            text.attr({
              "font-size": 20,
              "text-anchor": "left",
              "font-family": "Courier New",
              "font-style": "normal",
              "style": ""
            });
            text.node.style = "";
          }
        }
      }
      lmax = Math.floor(l / 1000);
      results = [];
      for (i = k = 0, ref1 = lmax; 0 <= ref1 ? k <= ref1 : k >= ref1; i = 0 <= ref1 ? ++k : --k) {
        angle = (i / (l / 1000)) * 360;
        start = helper.calculateCircle(this.center, this.radius - 5, angle);
        end = helper.calculateCircle(this.center, this.radius - 10, angle);
        line = paper.path("M" + start[0] + " " + start[1] + "L" + end[0] + " " + end[1]);
        line.attr({
          "stroke": "#000",
          "stroke-width": 2
        });
        r = this.radius;
        if (angle > 90 && angle < 270) {
          start = helper.calculateCircle(this.center, r - 8, angle + 9);
          middle = helper.calculateCircle(this.center, r - 8, angle + 1);
          fix = 0;
        } else {
          start = helper.calculateCircle(this.center, r - 17, angle + 1);
          middle = helper.calculateCircle(this.center, r - 17, angle + 9);
          fix = 1;
        }
        d = ["M", start[0], start[1], "A", r, r, 0, 0, fix, middle[0], middle[1]].join(" ");
        circle = paper.path(d);
        circle.attr({
          "stroke": "#000",
          "stroke-width": 0
        });
        pathId = "plasmid+" + this.id + "bp" + i;
        circle.node.id = pathId;
        text = paper.text(5, 0, i * 1000);
        text.attr({
          "font-size": 12,
          "text-anchor": "left",
          "font-family": "Courier New",
          "font-style": "normal",
          "textpath": pathId
        });
        results.push(text.node.style = "");
      }
      return results;
    };

    Plasmid.prototype.draw = function(id) {
      var paper, realWidth, self;
      console.log("draw", id);
      self = this;
      this.width = this.size[0];
      this.height = this.size[1];
      paper = Raphael(id, this.width, this.height);
      this.ob = $("#" + id);
      this.svgOb = this.ob.find("svg:first");
      this.svg = self.drawSVG(paper);
      realWidth = (this.radius + 20 * this.layers.length - 30) * 2;
      this.ob.css({
        position: "relative",
        "transform": "scale(" + this.scale + ")",
        "transform-origin": "top left"
      });

      /*  
      if typeof window.Pablo == "function"
        pabloNode = window.Pablo($("svg")[0])  
        bbox = pabloNode.bbox();
      
      
        border      = {x:10, y:10}
        textPadding = 200
      
        bbox.width  = bbox.width + border.x
        bbox.height = bbox.height + border.x
      
        if @radius*2+textPadding >  bbox.width 
           bbox.x      = bbox.x - (@radius*2+textPadding-bbox.width)/2
           bbox.width  = @radius*2+textPadding
        else
          bbox.x      = bbox.x - border.x/2
      
        if @radius*2+textPadding >  bbox.height 
          bbox.y      = bbox.y - (@radius*2+textPadding-bbox.height)/2
          bbox.height  = @radius*2+textPadding
        else
          bbox.y      = bbox.y - border.y/2     
      
        @crop = bbox
      
        pabloNode.cropSize(bbox);
      
        @size   = [@crop.width,@crop.height]
        @center = [@center[0]-@crop.x,@center[1]-@crop.y]
      
        @addEvents()  
        console.log "crop", @crop, @size, @center
       */
      this.ob.find("svg").css({
        position: "relative",
        float: "left"
      });
      return this.ob.find("svg")[0].outerHTML;
    };

    Plasmid.prototype.getSVG = function() {
      return this.draw();
    };

    Plasmid.prototype.selectAll = function() {
      this.showStart({
        angle: 0,
        bp: 0
      });
      return this.selectEnd({
        angle: 359.99,
        bp: this.bp.length
      });
    };

    Plasmid.prototype.copySequence = function() {
      var err, error, value;
      try {
        value = this.bp.slice(this.selectingStartData.bp, this.selectingEndData.bp);
        this.obCopy.text(value).select();
        return document.execCommand('copy');
      } catch (error) {
        err = error;
        return console.log('Oops, unable to copy');
      }
    };

    Plasmid.prototype.addEvents = function() {
      var plasmid;
      plasmid = this;
      this.selecter.hide();
      Mousetrap.bind('command+a', function(e) {
        if (window.activePlasmid) {
          window.activePlasmid.selectAll();
        }
        return false;
      });
      Mousetrap.bind('command+c', function(e) {
        if (window.activePlasmid) {
          window.activePlasmid.copySequence();
        }
        return false;
      });
      document.onselectstart = function() {
        if (window.activePlasmid.selecting) {
          return false;
        }
      };
      $(window).mousedown(function(e) {
        return 1;
      });
      return this.ob.mousedown(function(e) {
        var data;
        if (window.activePlasmid) {
          window.activePlasmid.deselect();
        }
        window.activePlasmid = plasmid;
        data = plasmid.calculateClick(this, e);
        return plasmid.selectStart(data);
      }).mousemove(function(e) {
        var data;
        window.activePlasmid = plasmid;
        data = plasmid.calculateClick(this, e);
        return plasmid.selectMove(data);
      }).mouseup(function(e) {
        var data;
        window.activePlasmid = plasmid;
        data = plasmid.calculateClick(this, e);
        return plasmid.selectEnd(data);
      });
    };

    Plasmid.prototype.calculateClick = function(self, e) {
      var angle, bp, cutX, cutY, data, distance, parentOffset, relX, relY, x, y;
      parentOffset = this.ob.offset();
      relX = e.pageX - parentOffset.left;
      relY = e.pageY - parentOffset.top;
      cutX = this.center[0] * this.scale;
      cutY = this.center[1] * this.scale;
      x = relX - cutX;
      y = relY - cutY;
      angle = 360 - (Math.atan2(x, y) * 180 / Math.PI + 180);
      bp = Math.round(this.bp.length * (angle / 360));
      distance = Math.sqrt(x * x + y * y);
      data = {
        angle: angle,
        bp: bp,
        distance: distance
      };
      return data;
    };

    Plasmid.prototype.deselect = function() {
      return this.selecter.hide();
    };

    Plasmid.prototype.selectStart = function(data) {
      if (data.distance > (this.radius - 20) * this.scale && data.distance < (this.radius + 20) * this.scale) {
        return this.showStart(data);
      } else {
        return this.selecter.hide();
      }
    };

    Plasmid.prototype.showStart = function(data) {
      var angle;
      angle = data.angle;
      this.selecting = 1;
      this.startedSelecting = 1;
      this.selectingStartData = data;
      this.selectingEndData = data;
      this.selecter.startAngle = angle;
      this.selecter.endAngle = angle + 0.1;
      this.selecter.updatePath(this.selectingStartData.bp, this.selectingEndData.bp);
      return this.selecter.show();
    };

    Plasmid.prototype.selectMove = function(data) {
      var angle;
      angle = data.angle;
      if (this.selecting) {
        this.selectingEndData = data;
        this.selecter.startAngle = this.selectingStartData.angle;
        this.selecter.endAngle = this.selectingEndData.angle;
        if (this.selecter.startAngle === this.selecter.endAngle) {
          this.selecter.endAngle += 0.1;
        }
        return this.selecter.updatePath(this.selectingStartData.bp, this.selectingEndData.bp);
      }
    };

    Plasmid.prototype.selectEnd = function(data) {
      var angle;
      if (this.selecting) {
        angle = data.angle;
        this.selectMove(data);
        return this.selecting = 0;
      }
    };

    Plasmid.prototype.getLayer = function(startAngle, endAngle, t) {
      var index, j, layer, len, n, nope, ref, text;
      nope = 1;
      ref = this.layers;
      for (index in ref) {
        layer = ref[index];
        if (nope === 0) {
          if (!this.layers[index - 1]) {
            this.layers[index - 1] = [];
          }
          this.layers[index - 1].push([startAngle, endAngle]);
          if (endAngle > 360) {
            this.layers[index - 1].push([0, endAngle % 360]);
          }
          return index - 1;
        }
        nope = 0;
        for (j = 0, len = layer.length; j < len; j++) {
          text = layer[j];
          if (!((startAngle < text[0] && endAngle < text[0]) || (startAngle > text[1] && endAngle > text[1])) || (endAngle > 360 && endAngle % 360 > text[0])) {
            nope = 1;
            break;
          }
        }
      }
      n = nope ? this.layers.length : this.layers.length - 1;
      if (!this.layers[n]) {
        this.layers[n] = [];
      }
      this.layers[n].push([startAngle, endAngle]);
      if (endAngle > 360) {
        this.layers[index - 1].push([0, endAngle % 360]);
      }
      return n;
    };

    Plasmid.prototype.getRadius = function(startAngle, endAngle, text) {
      var n;
      n = this.getLayer(startAngle, endAngle, text);
      if (n === 0) {
        return this.radius - 80;
      } else {
        return this.radius - 10 + 20 * n;
      }
    };

    Plasmid.prototype.getPNG = function() {
      return saveSvgAsPng(this.ob.find("svg")[0], this.name + ".png");
    };

    return Plasmid;

  })();

  module.exports = Plasmid;

}).call(this);
