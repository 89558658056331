helper 	= require('../helper')
Arrow 		= require('./arrow')
selectId=1000
class Select
	constructor: (data) ->

		@borderColor="#000"
		@fillColor	="#eee"
		@opacity	= 1
		@fontSize   = 12
		@text  		= ""
		@class		="arrow"
		@arrow      = [false,false] 	#start, front arrow 

		@center		= [0,0]
		@radius		= 210
		@startAngle = 0
		@endAngle	= 0
		@width      = 30

		for index,value of data
			this[index]=value

		@id			= selectId

		selectId++
	calculatePath: () ->

		endAngle    =@endAngle
		startAngle  =@startAngle


		r = @radius

		length	= 2 * Math.PI * r

		@start  = helper.calculateCircle(@center,r,startAngle)
		@middle	= helper.calculateCircle(@center,r,startAngle+(endAngle-startAngle)/2)
		@end  	= helper.calculateCircle(@center,r,endAngle)
		
		diffAngle=Math.abs(@startAngle-@endAngle)
		if (diffAngle <3 || diffAngle>357) && startAngle>45 && startAngle < 315
			@textPosStart = helper.calculateCircle(@center,@radius+65,startAngle)
		else
			@textPosStart = helper.calculateCircle(@center,@radius+40,startAngle)
		
		@textPosEnd   = helper.calculateCircle(@center,@radius+40,endAngle)
		
		fix	= 0
		fix2= 1
		if endAngle<startAngle
			#fix = 1 if 360-Math.abs(endAngle-startAngle)>180 
			fix2=0 if 360-Math.abs(endAngle-startAngle)>180 
		else
			fix = 1 if Math.abs(endAngle-startAngle)>180 
		d = [
	        "M", @start[0], @start[1], 
	        "A", r, r, 0, fix , fix2, @end[0], @end[1]
	    ].join(" ");
	updatePath: (start,end) ->
		d=@calculatePath()
		@obCircle.attr("d",d)
		@obTextStart.attr(x:@textPosStart[0],y:@textPosStart[1])
		

		diffAngle=Math.abs(@startAngle-@endAngle)
		if diffAngle <3 || diffAngle>357
			@obTextEnd.hide()
			@obTextStart.text(start+"bp-"+end+"bp")
		else
			@obTextEnd.show()
			@obTextStart.text(start+"bp")
			@obTextEnd.attr(x:@textPosEnd[0],y:@textPosEnd[1])
			@obTextEnd.text(end+"bp")
			@obTextStart.text(start+"bp")





	hide: () ->
	    @obCircle.hide()
	    @obTextStart.hide()
	    @obTextEnd.hide()
	show: () ->
		@obCircle.show()
		@obTextStart.show()

	draw: (paper) ->
		attrs=
			"stroke":@borderColor
			"stroke-width":@width
			"stroke-opacity":@opacity
		d=@calculatePath()
	

		circle=paper.path(d);
		circle.attr(attrs)
		circle.node.setAttribute("class",@class+" circle id"+@id)
		circle.node.setAttribute("data",@id)

		@obCircle=$(circle.node)

		text=paper.text(@textPosStart[0],@textPosStart[1],"1000bp");
		text.attr({ "font-size": @fontSize,"text-anchor":"middle", "font-family": "Courier New", "font-style": "normal"});
		text.node.style=""
		text.node.setAttribute("class",@class+" id"+@id)
		text.node.setAttribute("data",@id)

		@obTextStart=$(text.node)

		text=paper.text(@textPosEnd[0],@textPosEnd[1],"1000bp");
		text.attr({ "font-size": @fontSize,"text-anchor":"middle", "font-family": "Courier New", "font-style": "normal"});
		text.node.style=""
		text.node.setAttribute("class",@class+" id"+@id)
		text.node.setAttribute("data",@id)	

		@obTextEnd=$(text.node)

module.exports=Select
			
