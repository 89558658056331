// Generated by CoffeeScript 1.10.0
(function() {
  var Feature;

  Feature = (function() {
    function Feature() {}

    return Feature;

  })();

  module.exports = Feature;

}).call(this);
