// Generated by CoffeeScript 1.10.0
(function() {
  var Buffer, FCS, FacsChart, facsId;

  FCS = require("fcs");

  Buffer = require('buffer/').Buffer;

  facsId = 0;

  FacsChart = (function() {
    function FacsChart(data) {
      var databuf, fcs, options;
      this.id = facsId;
      this.scale = 0.75;
      facsId++;
      window.activePlasmid = this;
      this.ob = null;
      this.obCopy = $("<textarea/>").appendTo("body");
      this.obCopy.css({
        position: "absolute",
        top: "-100px",
        left: "-100px"
      });
      this.df = [
        {
          "name": "AmpR (3)",
          "typ": "feature",
          "bp": "ATGAGTATTCAACATTTCCGTGTCGCCCTTATTCCCTTTTTTGCGGCATTTTGCCTTCCTGTTTTTGCTCACCCAGAAACGCTGGTGAAAGTAAAAGATGCTGAAGATCAGTTGGGTGCACGAGTGGGTTACATCGAACTGGATCTCAACAGCGGTAAGATCCTTGAGAGTTTTCGCCCCGAAGAACGTTTTCCAATGATGAGCACTTTTAAAGTTCTGCTATGTGGCGCGGTATTATCCCGTGTTGACGCCGGGCAAGAGCAACTCGGTCGCCGCATACACTATTCTCAGAATGACTTGGTTGAGTACTCACCAGTCACAGAAAAGCATCTTACGGATGGCATGACAGTAAGAGAATTATGCAGTGCTGCCATAACCATGAGTGATAACACTGCGGCCAACTTACTTCTGACAACGATCGGAGGACCGAAGGAGCTAACCGCTTTTTTGCACAACATGGGGGATCATGTAACTCGCCTTGATCGTTGGGAACCGGAGCTGAATGAAGCCATACCAAACGACGAGCGTGACACCACGATGCCTGCAGCAATGGCAACAACGTTGCGCAAACTATTAACTGGCGAACTACTTACTCTAGCTTCCCGGCAACAATTAATAGACTGGATGGAGGCGGATAAAGTTGCAGGACCACTTCTGCGCTCGGCCCTTCCGGCTGGCTGGTTTATTGCTGATAAATCTGGAGCCGGTGAGCGTGGGTCTCGCGGTATCATTGCAGCACTGGGGCCAGATGGTAAGCCCTCCCGTATCGTAGTTATCTACACGACGGGGAGTCAGGCAACTATGGATGAACGAAATAGACAGATCGCTGAGATAGGTGCCTCACTGATTAAGCATTGGTAA"
        }, {
          "name": "CAP binding site",
          "typ": "feature",
          "bp": "TAATGTGAGTTAGCTCACTCAT"
        }, {
          "name": "KanR",
          "typ": "feature",
          "bp": "ATGGCAATTACCTTATCCGCAACTTCTTTACCTATTTCCGCCCGGATCCGGGCAGGTTCTCCGGCCGCTTGGGTGGAGAGGCTATTCGGCTATGACTGGGCACAACAGACAATCGGCTGCTCTGATGCCGCCGTGTTCCGGCTGTCAGCGCAGGGGCGCCCGGTTCTTTTTGTCAAGACCGACCTGTCCGGTGCCCTGAATGAACTGCAGGACGAGGCAGCGCGGCTATCGTGGCTGGCCACGACGGGCGTTCCTTGCGCAGCTGTGCTCGACGTTGTCACTGAAGCGGGAAGGGACTGGCTGCTATTGGGCGAAGTGCCGGGGCAGGATCTCCTGTCATCTCACCTTGCTCCTGCCGAGAAAGTATCCATCATGGCTGATGCAATGCGGCGGCTGCATACGCTTGATCCGGCTACCTGCCCATTCGACCACCAAGCGAAACATCGCATCGAGCGAGCACGTACTCGGATGGAAGCCGGTCTTGTCGATCAGGATGATCTGGACGAAGAGCATCAGGGGCTCGCGCCAGCCGAACTGTTCGCCAGGCTCAAGGCGCGCATGCCCGACGGCGAGGATCTCGTCGTGACCCATGGCGATGCCTGCTTGCCGAATATCATGGTGGAAAATGGCCGCTTTTCTGGATTCATCGACTGTGGCCGGCTGGGTGTGGCGGACCGCTATCAGGACATAGCGTTGGCTACCCGTGATATTGCTGAAGAGCTTGGCGGCGAATGGGCTGACCGCTTCCTCGTGCTTTACGGTATCGCCGCTCCCGATTCGCAGCGCATCGCCTTCTATCGCCTTCTTGACGAGTTCTTCTGA"
        }, {
          "name": "M13 fwd",
          "typ": "feature",
          "bp": "GTAAAACGACGGCCAGT"
        }, {
          "name": "SV40 ori",
          "typ": "feature",
          "bp": "ATCCCGCCCCTAACTCCGCCCAGTTCCGCCCATTCTCCGCCCCATGGCTGACTAATTTTTTTTATTTATGCAGAGGCCGAGGCCGCCTCGGCCTCTGAGCTATTCCAGAAGTAGTGAGGAGGCTTTTTTGGAGGCC"
        }, {
          "name": "T7 promoter",
          "typ": "feature",
          "bp": "TAATACGACTCACTATAGG"
        }, {
          "name": "M13 rev",
          "typ": "feature",
          "bp": "CAGGAAACAGCTATGAC"
        }, {
          "name": "SV40 poly(A) signal (2)",
          "typ": "feature",
          "bp": "AACTTGTTTATTGCAGCTTATAATGGTTACAAATAAAGCAATAGCATCACAAATTTCACAAATAAAGCATTTTTTTCACTGCATTCTAGTTGTGGTTTGTCCAAACTCATCAATGTATCTTATCATGTCTGGATC"
        }, {
          "name": "T3 promoter",
          "typ": "feature",
          "bp": "AATTAACCCTCACTAAAGG"
        }, {
          "name": "lac UV5 promoter",
          "typ": "feature",
          "bp": "TTTACACTTTATGCTTCCGGCTCGTATAATG"
        }, {
          "name": "lac operator (2)",
          "typ": "feature",
          "bp": "GGAATTGTGAGCGGATAACAATTCC"
        }, {
          "name": "ori",
          "typ": "feature",
          "bp": "TTGAGATCCTTTTTTTCTGCGCGTAATCTGCTGCTTGCAAACAAAAAAACCACCGCTACCAGCGGTGGTTTGTTTGCCGGATCAAGAGCTACCAACTCTTTTTCCGAAGGTAACTGGCTTCAGCAGAGCGCAGATACCAAATACTGTCCTTCTAGTGTAGCCGTAGTTAGGCCACCACTTCAAGAACTCTGTAGCACCGCCTACATACCTCGCTCTGCTAATCCTGTTACCAGTGGCTGCTGCCAGTGGCGATAAGTCGTGTCTTACCGGGTTGGACTCAAGACGATAGTTACCGGATAAGGCGCAGCGGTCGGGCTGAACGGGGGGTTCGTGCACACAGCCCAGCTTGGAGCGAACGACCTACACCGAACTGAGATACCTACAGCGTGAGCTATGAGAAAGCGCCACGCTTCCCGAAGGGAGAAAGGCGGACAGGTATCCGGTAAGCGGCAGGGTCGGAACAGGAGAGCGCACGAGGGAGCTTCCAGGGGGAAACGCCTGGTATCTTTATAGTCCTGTCGGGTTTCGCCACCTCTGACTTGAGCGTCGATTTTTGTGATGCTCGTCAGGGGGGCGGAGCCTATGGAAA"
        }, {
          "name": "AmpR promoter (2)",
          "typ": "feature",
          "bp": "CGCGGAACCCCTATTTGTTTATTTTTCTAAATACATTCAAATATGTATCCGCTCATGAGACAATAACCCTGATAAATGCTTCAATAATATTGAAAAAGGAAGAGT"
        }, {
          "name": "M13 ori (3)",
          "typ": "feature",
          "bp": "ACGCGCCCTGTAGCGGCGCATTAAGCGCGGCGGGTGTGGTGGTTACGCGCAGCGTGACCGCTACACTTGCCAGCGCCCTAGCGCCCGCTCCTTTCGCTTTCTTCCCTTCCTTTCTCGCCACGTTCGCCGGCTTTCCCCGTCAAGCTCTAAATCGGGGGCTCCCTTTAGGGTTCCGATTTAGTGCTTTACGGCACCTCGACCCCAAAAAACTTGATTTGGGTGATGGTTCACGTAGTGGGCCATCGCCCTGATAGACGGTTTTTCGCCCTTTGACGTTGGAGTCCACGTTCTTTAATAGTGGACTCTTGTTCCAAACTGGAACAACACTCAACCCTATCTCGGGCTATTCTTTTGATTTATAAGGGATTTTGCCGATTTCGG"
        }
      ];
      this.features = [];
      this.history = [];
      this.name = "Untiled";
      this.author = "";
      this.description = "";
      this.displayOptions = {
        editable: false,
        features: true,
        cutters: true,
        primers: false,
        linear: false
      };
      this.center = [400, 400];
      this.radius = 250;
      databuf = Buffer(data, "utf16");
      options = {
        dataFormat: "asNumber",
        eventsToRead: -1
      };
      fcs = new FCS(options, databuf);
      console.log(fcs.text);
      this.min = fcs.get$PnX("E");
      this.max = fcs.get$PnX("R");
      console.log(this.max, this.min);
      this.axis = fcs.get$PnX("N");
      this.axisName = fcs.get$PnX("S");
      this.dataPoints = fcs.dataAsNumbers;
    }

    FacsChart.prototype.draw = function(id) {
      var i, j, len, paper, point, ref, self;
      self = this;

      /*
      		@svg = raphael.generate(1000, 1000,(paper)->
      			self.drawSVG(paper)
      		)
       */
      this.width = 500;
      this.height = 500;
      paper = Raphael(id, this.width, this.height);
      this.ob = $("#" + id);
      this.ob.find("svg").svg();
      this.svgOb = this.ob.find("svg").svg("get");
      this.ob.find("svg").css({
        "transform": "scale(" + this.scale + ")",
        "transform-origin": "top left",
        "float": "left"
      });
      this.ob.css({
        width: this.width * this.scale + 'px',
        height: this.height * this.scale + 'px'
      });
      paper.path("M0 500 L500 500");
      paper.path("M0 500 L0 0");
      paper.path("M500 0 L500 500");
      paper.path("M500 0 L0 0");
      i = 0;
      ref = this.dataPoints;
      for (j = 0, len = ref.length; j < len; j++) {
        point = ref[j];
        i++;
        paper.circle((point[2] / this.max[2]) * this.width, this.height - (point[4] / this.max[4]) * this.width, 0.5);
      }
      return console.log(Math.log10(i));
    };

    return FacsChart;

  })();

  module.exports = FacsChart;

}).call(this);
