// Generated by CoffeeScript 1.10.0
(function() {
  var Import;

  Import = (function() {
    function Import() {}

    return Import;

  })();

}).call(this);
