// Generated by CoffeeScript 1.10.0
(function() {
  var Arrow, arrowId, helper;

  helper = require('../helper');

  arrowId = 1;

  Arrow = (function() {
    function Arrow(data) {
      var index, value;
      this.borderColor = "#000";
      this.fillColor = "#eee";
      this.opacity = 1;
      this.fontSize = 20;
      this.text = "";
      this["class"] = "arrow";
      this.arrow = [false, true];
      this.center = [0, 0];
      this.radius = 210;
      this.startAngle = 0;
      this.endAngle = 0;
      this.width = 25;
      this.plasmid = data.plasmid;
      for (index in data) {
        value = data[index];
        this[index] = value;
      }
      this.id = arrowId;
      if (this.text[this.text.length - 1] === ')') {
        this.text = this.text.slice(0, this.text.length - 3);
      }
      this.text = this.text.trim();
      arrowId++;
    }

    Arrow.prototype.calculatePath = function() {
      var d, end, endAngle, fix, length, middle, r, start, startAngle;
      if (this.startAngle > this.endAngle) {
        startAngle = this.endAngle;
        endAngle = this.startAngle;
      } else {
        endAngle = this.endAngle;
        startAngle = this.startAngle;
      }
      r = this.radius;
      length = 2 * Math.PI * r;
      start = helper.calculateCircle(this.center, r, startAngle);
      middle = helper.calculateCircle(this.center, r, startAngle + (endAngle - startAngle) / 2);
      end = helper.calculateCircle(this.center, r, endAngle);
      fix = 0;
      if (Math.abs(endAngle - startAngle) > 180) {
        fix = 1;
      }
      return d = ["M", start[0], start[1], "A", r, r, 0, fix, 1, end[0], end[1]].join(" ");
    };

    Arrow.prototype.updatePath = function() {
      var d;
      d = this.calculatePath();
      return $("path.select", this.plasmid.svgOb.root()).attr("d", d);
    };

    Arrow.prototype.hide = function() {
      return $("path.select", this.plasmid.svgOb.root()).hide();
    };

    Arrow.prototype.show = function() {
      return $("path.select", this.plasmid.svgOb.root()).show();
    };

    Arrow.prototype.draw = function(paper) {
      var angleLength, attrs, circle, curve, d, eTmp, end, endAngle, fix, l, length, line, lineLength, middle, offset, offsetAngle, offsetEnd, offsetStart, r, sTmp, start, startAngle, sumBottom, sumTop, text;
      attrs = {
        "stroke": this.borderColor,
        "stroke-width": this.width,
        "stroke-opacity": this.opacity
      };
      if (this.startAngle > this.endAngle) {
        startAngle = this.endAngle;
        endAngle = this.startAngle;
        if (this.arrow[1]) {
          attrs["arrow-start"] = "block-narrow-short";
        }
        if (this.arrow[0]) {
          attrs["arrow-end"] = "block-narrow-short";
        }
      } else {
        endAngle = this.endAngle;
        startAngle = this.startAngle;
        if (this.arrow[0]) {
          attrs["arrow-start"] = "block-narrow-short";
        }
        if (this.arrow[1]) {
          attrs["arrow-end"] = "block-narrow-short";
        }
      }
      r = this.radius;
      length = 2 * Math.PI * r;
      if (Math.abs(endAngle - startAngle) < 5) {
        delete attrs["arrow-start"];
        delete attrs["arrow-end"];
      }
      start = helper.calculateCircle(this.center, r, startAngle);
      middle = helper.calculateCircle(this.center, r, startAngle + (endAngle - startAngle) / 2);
      end = helper.calculateCircle(this.center, r, endAngle);
      fix = 0;
      if (Math.abs(endAngle - startAngle) > 180) {
        fix = 1;
      }
      d = ["M", start[0], start[1], "A", r, r, 0, fix, 1, end[0], end[1]].join(" ");
      circle = paper.path(d);
      circle.attr(attrs);
      circle.node.setAttribute("class", this["class"] + " id" + this.id);
      circle.node.setAttribute("data", this.id);
      offsetStart = 0;
      offsetEnd = 0;
      if (attrs["arrow-end"]) {
        offsetEnd = 1;
      }
      if (attrs["arrow-start"]) {
        offsetStart = 1;
      }
      r = this.radius;
      length = 2 * Math.PI * r;
      start = helper.calculateCircle(this.center, r, startAngle + (offsetStart / length * 360));
      middle = helper.calculateCircle(this.center, r, startAngle + (endAngle - startAngle) / 2);
      end = helper.calculateCircle(this.center, r, endAngle - (offsetEnd / length * 360));
      fix = 0;
      if (Math.abs(endAngle - startAngle) > 180) {
        fix = 1;
      }
      d = ["M", start[0], start[1], "A", r, r, 0, fix, 1, end[0], end[1]].join(" ");
      circle = paper.path(d);
      attrs["stroke"] = this.fillColor;
      attrs["stroke-width"] = this.width - 2;
      circle.attr(attrs);
      circle.node.setAttribute("class", this["class"] + " id" + this.id);
      circle.node.setAttribute("data", this.id);
      d = ["M", start[0], start[1], "A", r, r, 0, fix, 1, end[0], end[1]].join(" ");
      l = this.text.length * 0.6 * this.fontSize;
      lineLength = 2 * Math.PI * r;
      angleLength = l / lineLength * 360;
      offsetAngle = (Math.abs(endAngle - startAngle) - angleLength) / 2;
      offset = offsetAngle * lineLength / 360;
      if (!attrs["arrow-end"]) {
        start = helper.calculateCircle(this.center, r - this.width / 2, endAngle);
        end = helper.calculateCircle(this.center, r + this.width / 2, endAngle);
        d = ["M", start[0], start[1], "L", start[0], start[1], end[0], end[1]].join(" ");
        line = paper.path(d);
        line.attr({
          "stroke": this.borderColor,
          "stroke-width": 1,
          "stroke-opacity": this.opacity
        });
        line.node.setAttribute("class", this["class"] + " id" + this.id);
        line.node.setAttribute("data", this.id);
      }
      if (!attrs["arrow-start"]) {
        start = helper.calculateCircle(this.center, r - this.width / 2, startAngle);
        end = helper.calculateCircle(this.center, r + this.width / 2, startAngle);
        d = ["M", start[0], start[1], "L", start[0], start[1], end[0], end[1]].join(" ");
        line = paper.path(d);
        line.attr({
          "stroke": this.borderColor,
          "stroke-width": 1,
          "stroke-opacity": this.opacity
        });
        line.node.setAttribute("class", this["class"] + " id" + this.id);
        line.node.setAttribute("data", this.id);
      }

      /*
      #full circle
      r = @radius-5
      start   = helper.calculateCircle(@center,r,@startAngle)
      middle  = helper.calculateCircle(@center,r,@startAngle+180)
      
        
            d = [
            "M", start[0], start[1], 
            "A", r, r, 0, 0, 1, middle[0], middle[1]
            "A", r, r, 0, 0, 1, start[0], start[1]
      
        ].join(" ");
       */
      r = this.radius;
      if (offset < 0) {
        startAngle = startAngle + offsetAngle;
        endAngle = endAngle - offsetAngle;
        r = this.plasmid.getRadius(startAngle, endAngle, this.text);
        lineLength = 2 * Math.PI * r;
        angleLength = l / lineLength * 360;
        offsetAngle = (Math.abs(endAngle - startAngle) - angleLength) / 2;
        startAngle = startAngle + offsetAngle;
        endAngle = endAngle - offsetAngle;
        offset = 0;
      }
      sTmp = (startAngle + 90) % 360;
      eTmp = (endAngle + 90) % 360;
      if (sTmp < 180) {
        if (eTmp < 180) {
          sumTop = eTmp - sTmp;
          sumBottom = 0;
        } else {
          sumTop = 180 - sTmp;
          sumBottom = eTmp - 180;
        }
      } else {
        if (eTmp < 180) {
          sumTop = eTmp;
          sumBottom = 360 - sTmp;
        } else {
          sumBottom = 1;
          sumTop = 0;
        }
      }
      if (sumBottom > sumTop) {
        r = r + 5;
        curve = 0;
        start = helper.calculateCircle(this.center, r, endAngle);
        end = helper.calculateCircle(this.center, r, startAngle);
      } else {
        r = r - 5;
        curve = 1;
        start = helper.calculateCircle(this.center, r, startAngle);
        end = helper.calculateCircle(this.center, r, endAngle);
      }
      d = ["M", start[0], start[1], "A", r, r, 0, fix, curve, end[0], end[1]].join(" ");
      circle = paper.path(d);
      circle.attr({
        "stroke": "#000000",
        "stroke-width": 0
      });
      circle.node.setAttribute("class", this["class"] + " id" + this.id);
      circle.node.setAttribute("data", this.id);
      circle.node.id = "arrow" + this.id;
      text = paper.text(offset, 0, this.text);
      text.attr({
        "font-size": this.fontSize,
        "text-anchor": "left",
        "font-family": "Courier New",
        "font-style": "normal",
        "textpath": "arrow" + this.id
      });
      text.node.style = "";
      text.node.setAttribute("class", this["class"] + " id" + this.id);
      return text.node.setAttribute("data", this.id);
    };

    return Arrow;

  })();

  module.exports = Arrow;

}).call(this);
