// Generated by CoffeeScript 1.10.0
(function() {
  var $, App, FacsChart, Plasmid, fs, jQuery;

  Plasmid = require('./scripts/modules/plasmid');

  FacsChart = require('./scripts/modules/facs');

  jQuery = require('jquery');

  $ = jQuery;

  fs = require('fs');


  /*
  xhr = new XMLHttpRequest();
  xhr.open('GET', '/data/AMO1-d3-puro-C1.035', true);
  xhr.responseType = 'arraybuffer';
  
  xhr.onload = (e) ->
      window.facs = new FacsChart(new Uint8Array(this.response));
      facs.draw("container1")	
  
  xhr.send();
   */


  /*
  $.get( "/data/AMO1-d3-puro-C1.035", (data) ->
      window.facs = new FacsChart(data);
      facs.draw("container1")
  )
   */

  App = (function() {
    function App() {}

    App.prototype.load = function() {
      return console.log("create App");

      /*
      		jQuery.getJSON( "_public/features.json", (data) ->
      			window.commonFeatures =  data
      			console.log("result");
       		)
       */
    };

    App.prototype.render = function(dna) {
      var features, plasmid;
      global.window.console.log("render");
      features = JSON.parse(fs.readFileSync("./app/assets/features.json", 'utf8'));
      plasmid = new Plasmid(dna, {
        name: "Untitled"
      }, features);
      return plasmid;
    };

    return App;

  })();

  module.exports = App;

}).call(this);
