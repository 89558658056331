// Generated by CoffeeScript 1.10.0
(function() {
  var Arrow, Select, helper, selectId;

  helper = require('../helper');

  Arrow = require('./arrow');

  selectId = 1000;

  Select = (function() {
    function Select(data) {
      var index, value;
      this.borderColor = "#000";
      this.fillColor = "#eee";
      this.opacity = 1;
      this.fontSize = 12;
      this.text = "";
      this["class"] = "arrow";
      this.arrow = [false, false];
      this.center = [0, 0];
      this.radius = 210;
      this.startAngle = 0;
      this.endAngle = 0;
      this.width = 30;
      for (index in data) {
        value = data[index];
        this[index] = value;
      }
      this.id = selectId;
      selectId++;
    }

    Select.prototype.calculatePath = function() {
      var d, diffAngle, endAngle, fix, fix2, length, r, startAngle;
      endAngle = this.endAngle;
      startAngle = this.startAngle;
      r = this.radius;
      length = 2 * Math.PI * r;
      this.start = helper.calculateCircle(this.center, r, startAngle);
      this.middle = helper.calculateCircle(this.center, r, startAngle + (endAngle - startAngle) / 2);
      this.end = helper.calculateCircle(this.center, r, endAngle);
      diffAngle = Math.abs(this.startAngle - this.endAngle);
      if ((diffAngle < 3 || diffAngle > 357) && startAngle > 45 && startAngle < 315) {
        this.textPosStart = helper.calculateCircle(this.center, this.radius + 65, startAngle);
      } else {
        this.textPosStart = helper.calculateCircle(this.center, this.radius + 40, startAngle);
      }
      this.textPosEnd = helper.calculateCircle(this.center, this.radius + 40, endAngle);
      fix = 0;
      fix2 = 1;
      if (endAngle < startAngle) {
        if (360 - Math.abs(endAngle - startAngle) > 180) {
          fix2 = 0;
        }
      } else {
        if (Math.abs(endAngle - startAngle) > 180) {
          fix = 1;
        }
      }
      return d = ["M", this.start[0], this.start[1], "A", r, r, 0, fix, fix2, this.end[0], this.end[1]].join(" ");
    };

    Select.prototype.updatePath = function(start, end) {
      var d, diffAngle;
      d = this.calculatePath();
      this.obCircle.attr("d", d);
      this.obTextStart.attr({
        x: this.textPosStart[0],
        y: this.textPosStart[1]
      });
      diffAngle = Math.abs(this.startAngle - this.endAngle);
      if (diffAngle < 3 || diffAngle > 357) {
        this.obTextEnd.hide();
        return this.obTextStart.text(start + "bp-" + end + "bp");
      } else {
        this.obTextEnd.show();
        this.obTextStart.text(start + "bp");
        this.obTextEnd.attr({
          x: this.textPosEnd[0],
          y: this.textPosEnd[1]
        });
        this.obTextEnd.text(end + "bp");
        return this.obTextStart.text(start + "bp");
      }
    };

    Select.prototype.hide = function() {
      this.obCircle.hide();
      this.obTextStart.hide();
      return this.obTextEnd.hide();
    };

    Select.prototype.show = function() {
      this.obCircle.show();
      return this.obTextStart.show();
    };

    Select.prototype.draw = function(paper) {
      var attrs, circle, d, text;
      attrs = {
        "stroke": this.borderColor,
        "stroke-width": this.width,
        "stroke-opacity": this.opacity
      };
      d = this.calculatePath();
      circle = paper.path(d);
      circle.attr(attrs);
      circle.node.setAttribute("class", this["class"] + " circle id" + this.id);
      circle.node.setAttribute("data", this.id);
      this.obCircle = $(circle.node);
      text = paper.text(this.textPosStart[0], this.textPosStart[1], "1000bp");
      text.attr({
        "font-size": this.fontSize,
        "text-anchor": "middle",
        "font-family": "Courier New",
        "font-style": "normal"
      });
      text.node.style = "";
      text.node.setAttribute("class", this["class"] + " id" + this.id);
      text.node.setAttribute("data", this.id);
      this.obTextStart = $(text.node);
      text = paper.text(this.textPosEnd[0], this.textPosEnd[1], "1000bp");
      text.attr({
        "font-size": this.fontSize,
        "text-anchor": "middle",
        "font-family": "Courier New",
        "font-style": "normal"
      });
      text.node.style = "";
      text.node.setAttribute("class", this["class"] + " id" + this.id);
      text.node.setAttribute("data", this.id);
      return this.obTextEnd = $(text.node);
    };

    return Select;

  })();

  module.exports = Select;

}).call(this);
